import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type LinkButtonStyle = 'primary' | 'secondary';

interface LinkButtonProps {
  buttonStyle?: LinkButtonStyle
}

const LinkButton = styled(Link)`
  border: none;
  padding: 0.6em 1.2em;
  background: ${(props: LinkButtonProps) =>
        props.buttonStyle === 'secondary' ? '#96b29e' : '#388a5a'};
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin: 3px 2px 3px 0;
  border-radius: 2px;
  line-height: normal;
  outline: none;
  font-weight: 400;
  text-align: center;

  &:visited,
  &:link {
    color: #fff;
  }
`;

export default LinkButton;
