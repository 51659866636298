import React from 'react';
import Input from './Input';
import Button from './Button';
import styled from '@emotion/styled';
import UnitChooser from './UnitChooser';
import { v4 as uuidv4 } from 'uuid';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactMarkdown from 'react-markdown';
import { IngredientHistoryItem, RecipeFormValues } from '../types';
import DeleteListItem from './DeleteListItem';
import { useTranslation } from 'react-i18next';
import Toggle from './Toggle';

interface RecipeFormProps {
  values: RecipeFormValues
  isOwner: boolean
  ingredientHistory?: IngredientHistoryItem[]
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  actionText: string
  setValues: (
    values: RecipeFormValues,
    shouldValidate?: boolean | undefined
  ) => any
}

const defaultIngredient = () => ({
    id: uuidv4(),
    name: '',
    quantity: '1',
    unit: 'l',
});

// const listCommands = [
//   {
//     commands: [
//       commands.orderedListCommand,
//       commands.unorderedListCommand,
//       commands.boldCommand,
//       commands.linkCommand,
//     ],
//   },
// ];

const RecipeForm: React.FunctionComponent<RecipeFormProps> = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    actionText,
    ingredientHistory,
    isOwner,
}) => {
    const { t } = useTranslation();

    const ingredientUnit: { [key: string]: string } = (
        ingredientHistory || []
    ).reduce((a, b) => ({ ...a, [b.name]: b.unit }), {});
    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write'
    );
    return (
        <FormWrapper onSubmit={handleSubmit}>
            <FormPartWrapper>
                <FormPart>
                    <Input
                        label={t('recipes.recipeForm.values.title')}
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                    <Input
                        label={t('recipes.recipeForm.values.numberOfPeople')}
                        name="numberOfPeople"
                        value={values.numberOfPeople}
                        handleChange={handleChange}
                    />
                    <Input
                        label={t('recipes.recipeForm.values.url')}
                        name="url"
                        value={values.url}
                        handleChange={handleChange}
                    />

                    <FieldSet>
                        {values.ingredients.map((ingredient, idx) => {
                            const autofocus = idx > 0 && ingredient.name === '';
                            const ingredientNameName = `ingredients[${idx}].name`;
                            const ingredientUnitName = `ingredients[${idx}].unit`;
                            return (
                                <Ingredient key={ingredient.id}>
                                    <IngredientName
                                        label={t('recipes.recipeForm.values.ingredientName')}
                                        name={ingredientNameName}
                                        value={ingredient.name}
                                        handleChange={(e) => {
                                            const evt = e as React.ChangeEvent<HTMLInputElement>;
                                            const guessedUnit =
                        ingredientUnit[evt.target.value as string] || 'l';
                                            setFieldValue(ingredientUnitName, guessedUnit, false);
                                            handleChange(e);
                                        }}
                                        width="100%"
                                        autoFocus={autofocus}
                                    />
                                    <IngredientQuantity
                                        label={t('recipes.recipeForm.values.ingredientQuantity')}
                                        type="number"
                                        name={`ingredients[${idx}].quantity`}
                                        inputMode="numeric"
                                        value={ingredient.quantity}
                                        handleChange={handleChange}
                                        width="100%"
                                    />
                                    <IngredientUnit
                                        name={ingredientUnitName}
                                        value={ingredient.unit}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        label={t('recipes.recipeForm.values.ingredientUnit')}
                                    />
                                    <div>
                                        <DeleteButton
                                            handleDeleteItem={() => {
                                                const newIngredients = values.ingredients.filter(
                                                    (ingr) => {
                                                        return ingr.id !== ingredient.id;
                                                    }
                                                );
                                                return setFieldValue('ingredients', newIngredients);
                                            }}
                                        />
                                    </div>
                                </Ingredient>
                            );
                        })}
                        <div>
                            <div>
                                <Button
                                    buttonStyle="secondary"
                                    type="button"
                                    onClick={() => {
                                        const newIngredients = [
                                            ...values.ingredients,
                                            defaultIngredient(),
                                        ];
                                        return setFieldValue('ingredients', newIngredients);
                                    }}
                                >
                                    {t('recipes.recipeForm.addIngredientButton')}
                                </Button>
                            </div>
                        </div>
                    </FieldSet>
                </FormPart>
                <FormPart>
                    <FieldSet>
                        <legend>{t('recipes.recipeForm.values.description')}</legend>
                        <ReactMde
                            value={values.description}
                            onChange={(val) => setFieldValue('description', val)}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={async (markdown) => (
                                <ReactMarkdown>{markdown}</ReactMarkdown>
                            )}
                        />
                    </FieldSet>
                    {isOwner && (
                        <FieldSet>
                            <legend>{t('recipes.recipeForm.values.collaborators')}</legend>
                            <CollaboratorsList>
                                {values.collaborators.map((friend, idx) => {
                                    return (
                                        <li key={`collaborators[${idx}].shareWith`}>
                                            <Toggle
                                                name={`collaborators[${idx}].shareWith`}
                                                value={friend.shareWith}
                                                handleChange={handleChange}
                                                label={friend.displayName || friend.email}
                                            />
                                        </li>
                                    );
                                })}
                            </CollaboratorsList>
                        </FieldSet>
                    )}
                </FormPart>
            </FormPartWrapper>
            <div>
                <Button type="submit">{actionText}</Button>
            </div>
        </FormWrapper>
    );
};

const FormWrapper = styled.form`
  font-size: 16px;

  @media only screen and (min-width: 1300px) {
    max-width: 1100px;
  }
`;

const CollaboratorsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0 0 20px;
  width: 100%;
  min-width: 0;
`;

const FormPartWrapper = styled.div`
  @media only screen and (min-width: 1300px) {
    display: flex;
    justify-content: space-between;
  }
`;

const FormPart = styled.div`
  margin-bottom: 20px;
  border: none;
  min-width: 0;
  padding: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 500px;
  }
`;

const Ingredient = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;

  & > div {
    margin-right: 3px;
  }

  @media only screen and (min-width: 750px) {
    width: 500px;
  }
`;

const IngredientName = styled(Input)`
  flex-grow: 4;
  flex-shrink: 1;
  width: 180px;
`;

const IngredientQuantity = styled(Input)`
  flex-grow: 2;
  flex-shrink: 2;
  width: 65px;
`;

const DeleteButton = styled(DeleteListItem)`
  line-height: 80px;
`;

const IngredientUnit = styled(UnitChooser)`
  flex-grow: 1;
  flex-shrink: 3;
  width: 10px;
`;

export default RecipeForm;
