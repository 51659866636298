import { Recipe, RecipeFormValues } from './types';

const toRecipeFormValues = (recipe: Recipe): RecipeFormValues => {
    const mendedIngredients = recipe.ingredients.map((ingredient: any) => {
        return {
            ...ingredient,
            quantity: '' + ingredient.quantity,
        };
    });
    return {
        ...recipe,
        ingredients: mendedIngredients,
        numberOfPeople: '' + recipe.numberOfPeople,
        collaborators: [],
    };
};

const toRecipe = (recipe: RecipeFormValues, owner?: string): Recipe => {
    const mendedIngredients = recipe.ingredients.map((ingredient: any) => {
        return {
            ...ingredient,
            quantity: parseFloat(ingredient.quantity),
        };
    });
    const { collaborators, ...rest } = recipe;
    const storedCollaborators = collaborators
        .filter((collaborator) => collaborator.shareWith)
        .map((collaborator) => collaborator.uid);
    if (owner && !storedCollaborators.includes(owner)) {
        storedCollaborators.push(owner);
    }
    return {
        ...rest,
        collaborators: storedCollaborators,
        ingredients: mendedIngredients,
        numberOfPeople: parseInt(recipe.numberOfPeople),
    };
};

export { toRecipe, toRecipeFormValues };
