import { combineReducers } from '@reduxjs/toolkit';
import friendReducer, { namespace as friendNamespace } from '../features/friends/friendSlice';
import authReducer, { namespace as authNamespace } from './authSlice';
import { api } from '../apis/firebaseApiSlice';

export const rootReducer = combineReducers({
    [friendNamespace]: friendReducer,
    [authNamespace]: authReducer,
    [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>
