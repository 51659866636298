import React from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import Main from '../../Main';
import IngredientForm from '../../components/IngredientForm';
import { IngredientHistoryItem } from '../../types';
import FullPageLoading from '../../components/FullPageLoading';
import { useTranslation } from 'react-i18next';
import { useGetIngredientByIdQuery, useUpdateIngredientMutation } from '../../apis/firebaseApiSlice';

interface EditIngredientFormProps {
  ingredient: IngredientHistoryItem
}

const EditIngredientForm: React.FunctionComponent<EditIngredientFormProps> = ({
    ingredient,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [updateIngredient] = useUpdateIngredientMutation();
    const formik = useFormik({
        initialValues: {
            name: ingredient.name,
            unit: ingredient.unit,
            category: ingredient.category,
            isBasicIngredient: ingredient.isBasicIngredient,
        },
        onSubmit: async (values) => {
            const ingredientFormValues = {
                ...ingredient,
                name: values.name,
                category: values.category || '',
                unit: values.unit,
                isBasicIngredient: values.isBasicIngredient || false,
                updated: new Date().toISOString(),
            };
            updateIngredient(ingredientFormValues);
            navigate(`/ingredients`);
        },
    });

    return (
        <IngredientForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            actionText={t('ingredients.edit.callToAction')}
            setFieldValue={formik.setFieldValue}
        />
    );
};

const EditIngredient = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data: ingredient, isLoading, isError } = useGetIngredientByIdQuery(id);

    if (!ingredient || !id || isLoading) {
        return <FullPageLoading />;
    }

    return (
        <Main title={t('ingredients.edit.title')}>
            {isError && <div>There was an error fetching the ingredient</div>}
            <EditIngredientForm ingredient={ingredient} />
        </Main>
    );
};

export default EditIngredient;
