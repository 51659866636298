import { useEffect, useState } from 'react';

// function debounce<T extends Function>(cb: T, wait = 20) {
//   let h: NodeJS.Timeout | undefined = undefined;
//   let callable = (...args: any) => {
//     if (h) {
//       clearTimeout(h);
//     }
//     h = setTimeout(() => cb(...args), wait);
//   };
//   return <T>(<any>callable);
// }

const useDebounce = (value: any, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
};

export default useDebounce;
