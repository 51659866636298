import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

const FullPageLoading = () => {
    return (
        <LoadingContainer>
            <FontAwesomeIcon icon="spinner" spin />
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
  font-size: 32px;
  width: 64px;
  margin: 0 auto;
`;

export default FullPageLoading;
