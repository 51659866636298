import React from 'react';
import styled from '@emotion/styled';

type ButtonStyle = 'primary' | 'secondary' | 'disabled' | 'danger';

interface ButtonProps {
  buttonStyle?: ButtonStyle
  disabled?: boolean
  [key: string]: any
}

const Button: React.FunctionComponent<ButtonProps> = ({
    disabled,
    children,
    buttonStyle,
    ...rest
}) => {
    return (
        <StyledButton
            disabled={disabled}
            {...rest}
            buttonStyle={buttonStyle || 'primary'}
        >
            {children}
        </StyledButton>
    );
};

interface StyledButtonProps {
  buttonStyle: ButtonStyle
}

const buttonColor = (style: string) => {
    switch (style) {
        case 'secondary':
            return '#167740';
        case 'disabled':
            return '#c6decf';
        case 'primary':
            return '#388a5a';
        case 'danger':
            return '#df4f4f';
        default:
            return '#167740';
    }
};

const StyledButton = styled.button`
  border: none;
  padding: 0.6em 1.2em;
  background: ${(props: StyledButtonProps) => buttonColor(props.buttonStyle)};
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline-block;
  margin: 3px 2px 3px 0;
  border-radius: 2px;
  line-height: normal;
  outline: none;

  &:disabled {
    background: ${buttonColor('disabled')};
    cursor: not-allowed;
  }
`;

export default Button;
