import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NewRecipe from './features/newRecipe/NewRecipe';
import SignUpForm from './features/signup/Signup';
import SignInForm from './features/signin/Signin';
import Recipes from './features/recipes/Recipes';

import EditIngredient from './features/editIngredient/EditIngredient';
import Ingredients from './features/ingredients/Ingredients';
import ShoppingListDetails from './features/shoppingListDetails/ShoppingListDetails';
import ShoppingLists from './features/shoppingLists/ShoppingLists';
import { RequireAuth } from './components/RequireAuth';
import Landing from './features/landing/Landing';
import './components/icons/fontawesome/all';

import EditRecipe from './features/editRecipe/EditRecipe';
import Layout from './Layout';
import RecipeDetails from './features/recipeDetails/RecipeDetails';
import EditProfile from './features/editProfile/EditProfile';
import EditFriends from './features/friends/EditFriends';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFriends, reset } from './features/friends/friendSlice';

import { RootState } from './app/rootReducer';
import { useAuthState } from 'react-firebase-hooks/auth';
import { myFirebase } from './components/Firebase/firebase';
import { getAuth } from 'firebase/auth';
import { setCredentials } from './app/authSlice';

function App() {
    const { friends } = useSelector((state: RootState) => state.friends);

    const dispatch = useDispatch();

    const auth = getAuth(myFirebase);
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (user) {
            dispatch(setCredentials({ uid: user.uid }));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user && !friends) {
            dispatch(fetchFriends());
        }
        if (!user) {
            dispatch(reset());
        }
    }, [dispatch, friends, loading, user]);

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <RequireAuth redirect="/recipes" reverse={true}>
                                <Landing/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/recipes/new" element={
                            <RequireAuth>
                                <NewRecipe/>
                            </RequireAuth>
                        }/>

                    <Route
                        path="/shoppinglists/:id" element={
                            <RequireAuth>
                                <ShoppingListDetails/>
                            </RequireAuth>
                        }/>
                    <Route
                        path="shoppinglists" element={
                            <RequireAuth>
                                <ShoppingLists/>
                            </RequireAuth>
                        }/>
                    <Route
                        path="/recipes/:id/edit" element={
                            <RequireAuth>
                                <EditRecipe/>
                            </RequireAuth>
                        }/>
                    <Route
                        path="/recipes/:id" element={
                            <RequireAuth>
                                <RecipeDetails/>
                            </RequireAuth>
                        }/>
                    <Route path="/recipes" element={
                        <RequireAuth>
                            <Recipes/>
                        </RequireAuth>}
                    />
                    <Route path="/ingredients/:id/edit" element={
                        <RequireAuth>
                            <EditIngredient/>
                        </RequireAuth>}
                    />
                    <Route path="/ingredients" element={
                        <RequireAuth>
                            <Ingredients/>
                        </RequireAuth>}
                    />
                    <Route path="/profile/edit" element={
                        <RequireAuth>
                            <EditProfile/>
                        </RequireAuth>}
                    />
                    <Route path="/profile/friends" element={
                        <RequireAuth>
                            <EditFriends/>
                        </RequireAuth>}
                    />
                    <Route path="/signup" element={<SignUpForm/>}/>
                    <Route path="/signin" element={<SignInForm/>}/>
                    {/*<Route path="/forgot" component={PasswordForgetPage} />*/}
                    {/*<Route path="/home" component={HomePage} />*/}
                    {/*<Route path="/account" component={AccountPage} />*/}
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
