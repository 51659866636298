import React from 'react';
import units from '../units.json';
import { useSelect } from 'downshift';
import styled from '@emotion/styled';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const blacklist: string[] = [
    'mcg',
    'oz',
    'lb',
    't',
    'fl-oz',
    'cup',
    'pnt',
    'qt',
    'gal',
    'mt',
];

const items = units
    .flatMap((unit) => unit.available)
    .filter((unit) => !blacklist.includes(unit));

interface UnitChooserProps {
  name: string
  value: string
  onChange: (name: string, value: any) => any
  label?: string
}

const UnitChooser: React.FunctionComponent<UnitChooserProps> = ({
    name,
    value,
    onChange,
    label,
}) => {
    const { t } = useTranslation();
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(name, selectedItem);
        },
        selectedItem: value,
        items,
    });
    const defaultToggleButtonProps = getToggleButtonProps();
    const toggleButtonProps = {
        ...defaultToggleButtonProps,
        onClick: (e: any) => {
            e.preventDefault();
            defaultToggleButtonProps.onClick(e);
        },
    };
    return (
        <Wrapper>
            <div>
                <label {...getLabelProps()}>{label}</label>
            </div>
            <SelectButton {...toggleButtonProps}>
                {t(`units.${selectedItem}`) || 'Elements'}
            </SelectButton>
            <UnstyledList {...getMenuProps()}>
                {isOpen &&
          items.map((item, index) => (
              <li
                  style={
                      highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}
                  }
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
              >
                  {t(`units.${item}`)}
              </li>
          ))}
            </UnstyledList>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: relative;
`;

const SelectButton = styled(Button)`
  width: 70px;
  padding: 0.6em 0.5em;
`;

const UnstyledList = styled.ul`
  width: 70px;
  list-style: none;
  margin: 0;
  font-size: 18px;
  padding: 0 0 0 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 150ms ease-out;
  transform-origin: top center;
  z-index: 100;
  position: absolute;
  top: 60px;
  background-color: white;

  & > li {
    padding: 0.1em 0.2em;
  }
`;

export default UnitChooser;
