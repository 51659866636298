import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Main from '../../Main';
import { RecipeIngredient } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import LinkButton from '../../components/LinkButton';
import ReactMarkdown from 'react-markdown';
import Button from '../../components/Button';
import FullPageLoading from '../../components/FullPageLoading';
import { useTranslation } from 'react-i18next';
import Owner from '../../components/Owner';
import { useDeleteRecipeMutation, useGetRecipeByIdQuery } from '../../apis/firebaseApiSlice';

const RecipeDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [marked, setMarked] = useState<string[]>([]);

    const { data: recipe, isLoading } = useGetRecipeByIdQuery(id);
    const [deleteRecipe, { isSuccess: isSuccessfullyDeleted }] = useDeleteRecipeMutation();

    useEffect(() => {
        if (isSuccessfullyDeleted) {
            navigate('/recipes');
        }
    }, [navigate, isSuccessfullyDeleted]);

    const markIngredient = (ingredient: RecipeIngredient) => {
        if (marked.includes(ingredient.id)) {
            setMarked(marked.filter((m) => m !== ingredient.id));
        } else {
            setMarked([...marked, ingredient.id]);
        }
    };

    if (!recipe || isLoading || Object.keys(recipe).length === 0 || !id) {
        return <FullPageLoading/>;
    }

    return (
        <Main className="mw-600" title={recipe.title}>
            <div title={t('recipes.details.numOfPeopleTitle')}>
                <FontAwesomeIcon icon="female"/>
                <FontAwesomeIcon icon="male"/> {recipe.numberOfPeople}
            </div>
            <Owner userId={recipe.owner}/>
            {recipe.url && (
                <div title="Url">
                    <FontAwesomeIcon icon="link"/>{' '}
                    <a href={recipe.url} target="_blank" rel="noopener noreferrer">
                        {t('recipes.details.goToRecipeUrl')}
                    </a>
                </div>
            )}

            <IngredientList>
                {recipe.ingredients.map((ingredient: RecipeIngredient) => {
                    return (
                        <IngredientListItem
                            key={ingredient.id}
                            isMarked={marked.includes(ingredient.id)}
                            onClick={() => markIngredient(ingredient)}
                        >
                            <IngredientName>{ingredient.name}</IngredientName>
                            <div>
                                {ingredient.quantity} {t(`units.${ingredient.unit}`)}
                            </div>
                        </IngredientListItem>
                    );
                })}
            </IngredientList>

            {recipe.description && (
                <div>
                    <h3>{t('recipes.details.description')}</h3>
                    <DescriptionWrapper>
                        <ReactMarkdown>{recipe.description}</ReactMarkdown>
                    </DescriptionWrapper>
                </div>
            )}
            <LinkButton to={`/recipes/${id}/edit`}>
                {t('recipes.details.editButton')}
            </LinkButton>
            <Button buttonStyle="danger" onClick={() => deleteRecipe(recipe)}>
                {t('recipes.details.deleteButton')}
            </Button>
        </Main>
    );
};

const DescriptionWrapper = styled.article`
  color: rgba(0, 0, 0, 0.8);
`;

const IngredientList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

interface IngredientListItemProps {
    isMarked: boolean
}

const IngredientListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.2px dotted rgb(72, 167, 112, 0.8);
  text-decoration: ${(props: IngredientListItemProps) =>
        props.isMarked ? 'line-through' : 'none'};
  color: ${(props: IngredientListItemProps) =>
        props.isMarked ? 'rgb(72, 167, 112, 0.7);' : 'inherit'};

  &:hover {
    background: rgba(232, 229, 215, 0.8);
  }
`;

const IngredientName = styled.div`
  width: 200px;
`;

export default RecipeDetails;
