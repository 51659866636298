import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as No } from './no.svg';
import { ReactComponent as Gb } from './gb.svg';
import { useTranslation } from 'react-i18next';

interface LanguageChooserProps {
  style?: any
  className?: string
}

const LanguageChooser: React.FunctionComponent<LanguageChooserProps> = ({
    className,
    style,
    children,
    ...rest
}) => {
    const currentLanguage = localStorage.getItem('i18nextLng');
    const { i18n } = useTranslation();
    const changeLanguage = async (lng: string) => {
        try {
            await i18n.changeLanguage(lng);
        } catch (e) {
            console.log('Error changing language', e);
        }
    };
    return (
        <ItemBase className={className} style={style} {...rest}>
            <FlagsContainer role="listbox">
                <FlagContainer
                    active={currentLanguage === 'no'}
                    aria-selected={currentLanguage === 'no'}
                    onClick={() => changeLanguage('no')}
                >
                    <No />
                </FlagContainer>
                <FlagContainer
                    active={currentLanguage === 'en'}
                    aria-selected={currentLanguage === 'en'}
                    onClick={() => changeLanguage('en')}
                >
                    <Gb />
                </FlagContainer>
            </FlagsContainer>
        </ItemBase>
    );
};

const ItemBase = styled.li`
  list-style: none;
  display: block;
  padding: 4px 8px 4px 16px;
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5;
`;

type FlagContainerProps = {
  active: boolean
};

const FlagContainer = styled.li`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: ${(props: FlagContainerProps) =>
        props.active ? '0 0 0 2px #c6decf' : 'none'};
  cursor: pointer;
`;

const FlagsContainer = styled.ul`
  display: flex;
  height: 25px;
  width: 100px;
  justify-content: flex-start;
  list-style: none;
  margin-left: 0;
  padding: 0;
`;

export default LanguageChooser;
