import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import firebase from '../components/Firebase/firebase';
import { IngredientHistoryItem, PersistedRecipe, ShoppingList } from '../types';
import firebaseModule from 'firebase/compat/app';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

type DocumentData = firebaseModule.firestore.DocumentData;
type QuerySnapshot = firebaseModule.firestore.QuerySnapshot<DocumentData>;
type DocumentSnapshot = firebaseModule.firestore.DocumentSnapshot<DocumentData>;
type CreatedItem = {
    id: string
}
const firebaseListQuery = async <T>(uid: string | undefined, query: (uid: string) => Promise<QuerySnapshot>, mapper?: (snapshot: DocumentData) => T): Promise<QueryReturnValue<T[]>> => {
    if (!uid) {
        return { data: [] as T[] };
    }
    try {
        const snapshot = await query(uid);
        const mappingFn = mapper ? mapper : ((doc: DocumentData) => {
            return doc.data();
        });
        const data = snapshot.docs.map(mappingFn);
        return { data: data as T[] };
    } catch (e) {
        return { error: e };
    }
};
const firebaseGetByIdQuery = async <T>(uid: string | undefined, query: (uid: string) => Promise<DocumentSnapshot>): Promise<QueryReturnValue<T | undefined>> => {
    if (!uid) {
        return { data: undefined };
    }
    try {
        const snapshot = await query(uid);
        const data = { ...snapshot.data(), id: snapshot.id } as unknown as T;
        return { data };
    } catch (e) {
        return { error: e };
    }
};

export const api = createApi({
    baseQuery: fakeBaseQuery(),
    tagTypes: ['Ingredient', 'Recipe', 'ShoppingList'],
    endpoints: (build) => ({
        getIngredients: build.query<IngredientHistoryItem[], void>({
            async queryFn(arg, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => {
                    return firebase.firestore
                        .collection(`users/${uid}/ingredients`)
                        .get();
                };
                return firebaseListQuery<IngredientHistoryItem>(uid, fetchSnapshot);
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }: IngredientHistoryItem) => ({ type: 'Ingredient' as const, id })),
                        { type: 'Ingredient', id: 'LIST' }]
                    : [{ type: 'Ingredient', id: 'LIST' }],
        }),
        getRecipes: build.query<PersistedRecipe[], void>({
            async queryFn(arg, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => {
                    return firebase.firestore
                        .collection(`/recipes`)
                        .where('collaborators', 'array-contains', uid)
                        .where('deleted', '==', null)
                        .orderBy('title')
                        .get();
                };
                const mapper = (doc: DocumentData) => ({ ...doc.data(), id: doc.id });
                return firebaseListQuery<PersistedRecipe>(uid, fetchSnapshot, mapper);
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }: PersistedRecipe) => ({ type: 'Recipe' as const, id })),
                        { type: 'Recipe', id: 'LIST' }]
                    : [{ type: 'Recipe', id: 'LIST' }],
        }),
        getShoppingLists: build.query<ShoppingList[], void>({
            async queryFn(arg, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => {
                    return firebase.firestore
                        .collection('shoppinglists')
                        .where('collaborators', 'array-contains', uid)
                        .orderBy('created', 'desc')
                        .get();
                };
                const mapper = (doc: DocumentData) => ({ ...doc.data(), id: doc.id });
                return firebaseListQuery<ShoppingList>(uid, fetchSnapshot, mapper);
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }: ShoppingList) => ({ type: 'ShoppingList' as const, id })),
                        { type: 'ShoppingList', id: 'LIST' }]
                    : [{ type: 'ShoppingList', id: 'LIST' }],
        }),
        getShoppingListById: build.query<ShoppingList, string | undefined>({
            async queryFn(id, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => await firebase.firestore
                    .collection('shoppinglists')
                    .doc(id)
                    .get();
                return firebaseGetByIdQuery(uid, fetchSnapshot);
            },
            providesTags: (result, error, id) => [{ type: 'ShoppingList', id }],
        }),
        getRecipeById: build.query<PersistedRecipe, string | undefined>({
            async queryFn(id, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => await firebase.firestore
                    .collection('recipes')
                    .doc(id)
                    .get();
                return firebaseGetByIdQuery(uid, fetchSnapshot);

            },
            providesTags: (result, error, id) => [{ type: 'Recipe', id }],
        }),
        getIngredientById: build.query<IngredientHistoryItem, string | undefined>({
            async queryFn(id, { getState }) {
                const uid = (getState() as any).auth?.uid;
                const fetchSnapshot = async () => await firebase.firestore
                    .collection(`users/${uid}/ingredients`)
                    .doc(id)
                    .get();
                return firebaseGetByIdQuery(uid, fetchSnapshot);
            },
            providesTags: (result, error, id) => [{ type: 'Ingredient', id }],
        }),
        updateShoppingList: build.mutation<void, ShoppingList>({
            async queryFn(updated, { getState }) {
                const uid = (getState() as any).auth?.uid;
                if (!uid) {
                    return { data: undefined };
                }
                try {
                    await firebase.firestore
                        .collection('shoppinglists')
                        .doc(updated.id)
                        .update(updated);
                    return { data: undefined };
                } catch (e) {
                    console.log('Mutation failed', e);
                    return { error: e };
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'ShoppingList', id }],
        }),
        createShoppingList: build.mutation<CreatedItem, ShoppingList>({
            async queryFn(item, { getState }) {
                const uid = (getState() as any).auth?.uid;
                if (!uid) {
                    return { data: undefined };
                }
                try {
                    const { id } = await firebase.firestore
                        .collection('shoppinglists')
                        .add(item);
                    return {
                        data: {
                            id
                        }
                    };
                } catch (e) {
                    console.log('Mutation failed', e);
                    return { error: e };
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'ShoppingList', id }],
        }),
        deleteRecipe: build.mutation<void, PersistedRecipe>({
            async queryFn(deleted, { getState }) {
                const uid = (getState() as any).auth?.uid;
                if (!uid) {
                    return { data: undefined };
                }
                try {
                    await firebase.firestore
                        .collection('recipes')
                        .doc(deleted.id)
                        .update({
                            ...deleted,
                            deleted: new Date().toISOString(),
                        });
                    return { data: undefined };
                } catch (e) {
                    console.log('Mutation failed', e);
                    return { error: e };
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Recipe', id }],
        }),
        updateIngredient: build.mutation<void, IngredientHistoryItem>({
            async queryFn(updated, { getState }) {
                const uid = (getState() as any).auth?.uid;
                if (!uid) {
                    return { data: undefined };
                }
                try {
                    await firebase.firestore
                        .collection(`users/${uid}/ingredients`)
                        .doc(updated.id)
                        .update(updated);
                    return { data: undefined };
                } catch (e) {
                    console.log('Mutation failed', e);
                    return { error: e };
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Ingredient', id }],
        }),
    }),
});

export const {
    useGetIngredientsQuery,
    useGetIngredientByIdQuery,
    useGetRecipesQuery,
    useGetShoppingListsQuery,
    useGetShoppingListByIdQuery,
    useUpdateShoppingListMutation,
    useGetRecipeByIdQuery,
    useDeleteRecipeMutation,
    useUpdateIngredientMutation,
    useCreateShoppingListMutation,
} = api;
