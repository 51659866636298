import React from 'react';
import Main from '../../Main';
import LinkButton from '../../components/LinkButton';
import { useTranslation } from 'react-i18next';

const Landing = () => {
    const { t } = useTranslation();
    return (
        <Main className="w-500 center" title={t('landing.title')}>
            <LinkButton to="/signin">{t('landing.signInButton')}</LinkButton>
            <LinkButton to="/signup">{t('landing.signUpButton')}</LinkButton>
        </Main>
    );
};

export default Landing;
