import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
    apiKey: 'AIzaSyBEXU4eJaOgn0Nyktise7j_eeXONi1Xxc0',
    authDomain: 'feedus-b1bf0.firebaseapp.com',
    databaseURL: 'https://feedus-b1bf0.firebaseio.com',
    projectId: 'feedus-b1bf0',
    storageBucket: 'feedus-b1bf0.appspot.com',
    messagingSenderId: '352371123589',
    appId: '1:352371123589:web:33651981eefdff2cb690ec',
};

export const myFirebase = firebase.initializeApp(config);
const firebaseCollection = {
    auth: myFirebase.auth(),
    firestore: myFirebase.firestore(),
};
export default firebaseCollection;
