import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
    uid?: string
}

export const namespace = 'auth';
const slice = createSlice({
    name: namespace,
    initialState: { uid: undefined } as AuthState,
    reducers: {
        setCredentials: (
            state,
            { payload: { uid } }: PayloadAction<{ uid: string }>
        ) => {
            state.uid = uid;
        },
    },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

// export const selectCurrentUser = (state: RootState) => state.auth.user;
