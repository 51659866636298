import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import firebase from '../../components/Firebase/firebase';
import { Friend } from '../../types';

interface FriendState {
  friends: Friend[] | undefined
}

const initialState: FriendState = {
    friends: undefined,
};

export const namespace = 'friends';
export const slice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setFriends: (state, action: { payload: Friend[], type: string }) => {
            state.friends = action.payload;
        },
        pushFriend: (state, action: { payload: Friend, type: string }) => {
            state.friends = state.friends
                ? [...state.friends, action.payload]
                : [action.payload];
        },
        updateFriendInPlace: (state, action: { payload: Friend, type: string }) => {
            state.friends = (state.friends || []).map((friend) => {
                if (friend.id === action.payload.id) {
                    return action.payload;
                }
                return friend;
            });
        },
        reset: () => initialState,
    },
});

export const { setFriends, pushFriend, reset } = slice.actions;

export const fetchFriends = () => async (
    dispatch: Dispatch<{ payload: Friend[], type: string }>
) => {
    await firebase.firestore
        .collection(`users/${firebase.auth.currentUser?.uid}/friends`)
        .orderBy('email', 'asc')
        .get()
        .then((querySnapshot) => {
            const response: Friend[] = [];
            querySnapshot.forEach((doc) => {
                const documentData = doc.data();
                response.push({
                    ...documentData,
                    uid: documentData.uid,
                    id: doc.id,
                } as Friend);
            });
            dispatch(setFriends(response));
        })
        .catch((error) => {
            console.log('Error getting documents: ', error);
        });
};

export const addFriend = (friendUser: {
  id: string
  uid: string
  isDiscoverable: boolean
  displayName: string
  email: string
}) => async (dispatch: Dispatch<{ payload: Friend, type: string }>) => {
    const persistedFriendUser: Friend = {
        ...friendUser,
        alwaysShareWith: false,
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
    };
    const documentReference = await firebase.firestore
        .collection(`users/${firebase.auth.currentUser?.uid}/friends`)
        .add(persistedFriendUser);

    dispatch(pushFriend({ ...persistedFriendUser, id: documentReference.id }));
};

export const updateFriend = (friend: Friend) => async (
    dispatch: Dispatch<{ payload: Friend, type: string }>
) => {
    const updatedFriend: Friend = {
        ...friend,
        updated: new Date().toISOString(),
    };
    await firebase.firestore
        .collection(`users/${firebase.auth.currentUser?.uid}/friends`)
        .doc(updatedFriend.id)
        .update(updatedFriend);
    dispatch(slice.actions.updateFriendInPlace(updatedFriend));
};

export default slice.reducer;
