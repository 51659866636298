import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../../Main';
import Overview from '../../components/Overview';
import styled from '@emotion/styled';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ShoppingList } from '../../types';
import Owner from '../../components/Owner';
import { useGetShoppingListsQuery } from '../../apis/firebaseApiSlice';
import FullPageLoading from '../../components/FullPageLoading';

interface ShoppingListItemProps {
    shoppingList: ShoppingList
}

const ShoppingListItemElement: React.FunctionComponent<ShoppingListItemProps> = ({
    shoppingList,
}) => {
    const { t } = useTranslation();
    const recipes = shoppingList.recipes.slice(0, 2);
    const teaser =
        shoppingList.recipes.length > 2
            ? t('shoppingLists.list.item.andMore')
            : undefined;

    return (
        <ShoppingListLink to={`/shoppinglists/${shoppingList.id}`}>
            <div>
                {shoppingList.created && (
                    <p>{formatDistanceToNow(new Date(shoppingList.created))} ago</p>
                )}
                <UnstyledList>
                    {recipes.map((recipe) => {
                        return <li key={recipe.id}>{recipe.title}</li>;
                    })}
                    {teaser && <li>{teaser}</li>}
                </UnstyledList>
            </div>
            <Owner userId={shoppingList.owner}/>
        </ShoppingListLink>
    );
};

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ShoppingListLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  color: #fff;
  padding: 2px 5px 5px 5px;
  height: 100%;
  font-size: 16px;

  &:visited,
  &:link {
    color: #fff;
  }
`;

const ShoppingLists = () => {
    const { t } = useTranslation();

    const { data: shoppingLists, isLoading } = useGetShoppingListsQuery();

    if (!shoppingLists || isLoading) {
        return <FullPageLoading />;
    }

    return (
        <Main title={t('shoppingLists.list.title')}>
            <Overview>
                {shoppingLists.map((shoppingList) => (
                    <ShoppingListItemElement
                        key={shoppingList.id}
                        shoppingList={shoppingList}
                    />
                ))}
            </Overview>
        </Main>
    );
};

export default ShoppingLists;
