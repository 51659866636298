import React from 'react';
import styled from '@emotion/styled';

interface OverviewProps {}

const Overview: React.FunctionComponent<OverviewProps> = ({ children }) => {
    return (
        <OverviewContainer>
            {React.Children.map(children, (child) => (
                <Card>{child}</Card>
            ))}
        </OverviewContainer>
    );
};

const OverviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(265px, 1fr)
  ); /* Where the magic happens */
  grid-auto-rows: fit-content();
  grid-gap: 20px;
  margin: 20px 0;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 20px;
  border-radius: 5px;
  color: white;
  background-color: rgb(72, 167, 112);
`;

export default Overview;
