import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { myFirebase } from './Firebase/firebase';
import { getAuth } from 'firebase/auth';

const HideUnauth: React.FunctionComponent<any> = ({ children }) => {
    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);
    const authed = !!user;
    if (!authed) {
        return <div></div>;
    }
    return children;
};

export default HideUnauth;
