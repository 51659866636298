import units from './units.json';

const reverse = {} as { [key: string]: string };
units.forEach((category) =>
    category.available.forEach((unit) => {
        reverse[unit] = category.name;
    }));

export const category = (unit: string) => {
    return reverse[unit];
};
