// https://www.sitepoint.com/react-toggle-switch-reusable-component/
import React from 'react';
import styled from '@emotion/styled';

const Toggle: React.FunctionComponent<ToggleProps> = ({
    name,
    value,
    handleChange,
    label,
}) => {
    const size = 'small';
    return (
        <>
            <ToggleWrapper size={size}>
                <ToggleInput
                    type="checkbox"
                    checked={value}
                    name={name}
                    onChange={handleChange}
                    id={name}
                />
                <ToggleLabel htmlFor={name} checked={value}>
                    <ToggleInner checked={value} size={size} />
                    <ToggleSwitch checked={value} size={size} />
                </ToggleLabel>
            </ToggleWrapper>
            <ActualLabel htmlFor={name}>{label}</ActualLabel>
        </>
    );
};

const ActualLabel = styled.label`
  font-weight: bolder;
  margin-left: 10px;
  line-height: 20px;
  vertical-align: middle;
`;

const ToggleWrapper = styled.div`
  position: relative;
  width: ${(props: SizeProps) => (props.size === 'small' ? '40px' : '75px')};
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  height: ${(props: SizeProps) => (props.size === 'small' ? '20px' : 'auto')};
  line-height: ${(props: SizeProps) =>
        props.size === 'small' ? '20px' : 'auto'};

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ToggleInput = styled.input`
  display: none;
`;

interface CheckedProps {
  checked: boolean
  size?: 'small' | 'large'
}

const ToggleLabel = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
  right: ${(props: CheckedProps) => (props.checked ? '0px' : 'auto')};
`;

const ToggleInner = styled.span`
  display: block;
  width: 200%;
  margin-left: ${(props: CheckedProps) => (props.checked ? '0' : '-100%')};
  transition: margin 0.3s ease-in 0s;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    padding: 0;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;

    height: ${(props: CheckedProps) =>
        props.size === 'small' ? '20px' : '34px'};
    line-height: ${(props: CheckedProps) =>
        props.size === 'small' ? '20px' : '34px'};
  }

  &:before {
    content: "";
    //text-transform: uppercase;
    padding-left: 10px;
    background-color: #f90;
    color: #fff;
  }

  &:after {
    content: "";
    //  text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
  }
`;

const ToggleSwitch = styled.span`
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props: CheckedProps) => (props.checked ? '0px' : '40px')};
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;

  width: ${(props: CheckedProps) => (props.size === 'small' ? '16px' : '24px')};
  right: ${(props: CheckedProps) =>
        props.checked ? '0px' : props.size === 'small' ? '20px' : '40px'};
  margin: ${(props: CheckedProps) => (props.size === 'small' ? '2px' : '5px')};
`;

interface ToggleProps {
  name: string
  value: boolean
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  label?: string
}

interface SizeProps {
  size: 'small' | 'large'
}

export default Toggle;
