import React from 'react';
import styled from '@emotion/styled';

interface MainProps {
  children: React.ReactNode
  title: string
  className?: string
}

const Main: React.FunctionComponent<MainProps> = ({
    title,
    className,
    children,
}) => (
    <StyledView className={className}>
        <MainHeader>
            <header>
                <h1>{title}</h1>
            </header>
            <section></section>
        </MainHeader>
        <MainContent>{children}</MainContent>
    </StyledView>
);

const MainContent = styled.section`
  padding: 20px;
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
`;

const StyledView = styled.div`
  &.mw-500 {
    max-width: 500px;
  }

  &.mw-600 {
    max-width: 600px;
  }

  @media only screen and (min-width: 750px) {
    &.center {
      margin: 0 auto;
    }

    &.w-500 {
      width: 500px;
    }
  }
`;

export default Main;
