import React from 'react';
import { useFormik } from 'formik';
import firebase from '../../components/Firebase/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Main from '../../Main';
import RecipeForm from '../../components/RecipeForm';
import { Friend, PersistedRecipe } from '../../types';
import { toRecipe, toRecipeFormValues } from '../../model';
import FullPageLoading from '../../components/FullPageLoading';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useGetRecipeByIdQuery } from '../../apis/firebaseApiSlice';

interface EditRecipeFormProps {
  recipe: PersistedRecipe
  friends: Friend[]
}
const EditRecipeForm: React.FunctionComponent<EditRecipeFormProps> = ({
    recipe,
    friends,
}) => {
    const collaborators = friends.map((friend) => {
        return {
            uid: friend.uid,
            email: friend.email,
            displayName: friend.displayName,
            shareWith: recipe.collaborators?.includes(friend.uid) ?? false,
        };
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: { ...toRecipeFormValues(recipe), collaborators },
        onSubmit: async (values) => {
            const recipeFromValues = toRecipe(values, firebase.auth.currentUser?.uid);
            await firebase.firestore
                .collection('recipes')
                .doc(recipe.id)
                .update({
                    ...recipeFromValues,
                    updated: new Date().toISOString(),
                });
            navigate(`/recipes/${recipe.id}`);
        },
    });

    return (
        <RecipeForm
            isOwner={recipe.owner === firebase.auth.currentUser?.uid}
            values={formik.values}
            setValues={formik.setValues}
            setFieldValue={formik.setFieldValue}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            actionText={t('recipes.edit.callToAction')}
        />
    );
};

const EditRecipe = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { friends } = useSelector((state: RootState) => state.friends);
    const { data: recipe, isLoading } = useGetRecipeByIdQuery(id);

    if (!recipe || !id || !friends || isLoading) {
        return <FullPageLoading />;
    }

    return (
        <Main title={t('recipes.edit.title')}>
            <EditRecipeForm recipe={recipe} friends={friends} />
        </Main>
    );
};

export default EditRecipe;
