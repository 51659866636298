import { IngredientHistoryItem, PersistedRecipe, Recipe, RecipeIngredient, ShoppingList, Unit, } from '../types';
import { category } from '../unitCategories';
import convert from '../converter/convertFacade';
import { TFunction } from 'i18next';

const excludedUnits = ['fl-oz', 'in3', 'glas', 'kkp', 'kanna'] as Unit[];

const multiplyRecipeIngredients = (recipe: Recipe) => {
    const factor =
    (recipe.makeForNumberOfPeople || recipe.numberOfPeople) /
    recipe.numberOfPeople;
    const multipliedIngredients = recipe.ingredients.map((ingredient) => {
        const newQuantity = ingredient.quantity * factor;
        return {
            ...ingredient,
            quantity: newQuantity,
        };
    });
    return {
        ...recipe,
        ingredients: multipliedIngredients,
    };
};

const remaCategoryOrder = [
    'vegetables',
    'fruit',
    'bread',
    'rice',
    'pasta',
    'oriental',
    'weird',
    'fish',
    'meat',
    'produce',
    'frozen',
    'hermetics',
    'spices',
    'baking',
];

const recipesToShoppingList = (
    recipes: PersistedRecipe[],
    ingredients: IngredientHistoryItem[],
    t?: TFunction
): ShoppingList => {
    const all = recipes
        .map((recipe) => multiplyRecipeIngredients(recipe))
        .map((recipe) => recipe.ingredients)
        .flat();
    const grouped = groupBy(all, 'name') as { [key: string]: RecipeIngredient[] };
    const map = Object.keys(grouped).map((ingredientGroupKey: any) => {
        const groupedElement = grouped[ingredientGroupKey];
        const name = groupedElement[0].name;
        const firstUnit = groupedElement[0].unit;
        const category = ingredientGroupKey.split('.')[1];
        const recipeIds = groupedElement.map((el) => el.id);
        let quantity;

        if (['undefined'].includes(category)) {
            quantity = {
                val: groupedElement[0].quantity,
                unit: firstUnit,
            };
        } else {
            try {
                quantity = groupedElement.reduce((acc: number, ingredient) => {
                    return (
                        convert(ingredient.quantity).from(ingredient.unit).to(firstUnit) +
            acc
                    );
                }, 0);
                quantity = convert(quantity)
                    .from(firstUnit)
                    .toBest({ exclude: excludedUnits, cutOffNumber: 1 });
            } catch (e) {
                console.log(e);
                throw e;
            }
        }
        const unitText = t ? t(`units.${quantity.unit}`) : quantity.unit;
        const text = `${name} - ${quantity.val} ${unitText}`;
        const ingredientCategory = ingredients.find(
            (ingredient) => ingredient.name === name
        );
        return {
            id: ingredientGroupKey,
            text,
            checklistType: 'ingredient' as 'ingredient',
            recipeIds,
            category: ingredientCategory?.category ?? '',
            isBasicIngredient: ingredientCategory?.isBasicIngredient ?? false,
            unit: quantity.unit,
            isChecked: ingredientCategory?.isBasicIngredient ?? false,
            isDeleted: false,
        };
    });

    return {
        title: '',
        recipes,
        items: map.sort(
            (a, b) =>
                remaCategoryOrder.indexOf(a.category) -
        remaCategoryOrder.indexOf(b.category)
        ),
    };
};

const groupBy = (arr: any, key: string) =>
    arr.reduce((grouped: any, item: any) => {
        const groupKey = item[key] + '.' + category(item.unit);
        (grouped[groupKey] = grouped[groupKey] || []).push(item);
        return grouped;
    }, {});

export { recipesToShoppingList };
