import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import firebase, { myFirebase } from '../../components/Firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Main from '../../Main';
import RecipeForm from '../../components/RecipeForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { Friend, IngredientHistoryItem } from '../../types';
import { toRecipe } from '../../model';
import { getAuth, UserInfo } from 'firebase/auth';
import { useGetIngredientsQuery } from '../../apis/firebaseApiSlice';
import FullPageLoading from '../../components/FullPageLoading';

const defaultIngredient = () => ({
    id: uuidv4(),
    name: '',
    quantity: '1',
    unit: 'l',
});

interface NewRecipeFormProps {
  friends: Friend[]
  ingredientHistory: IngredientHistoryItem[]
  user: UserInfo
}

const NewRecipeForm: React.FunctionComponent<NewRecipeFormProps> = ({
    friends,
    ingredientHistory,
    user,
}) => {
    const navigate = useNavigate();
    const collaborators = friends.map((friend) => {
        return {
            uid: friend.uid,
            email: friend.email,
            displayName: friend.displayName,
            shareWith: friend.alwaysShareWith,
        };
    });
    const formik = useFormik({
        initialValues: {
            title: '',
            ingredients: [defaultIngredient()],
            numberOfPeople: '2',
            url: '',
            description: '',
            collaborators,
        },
        onSubmit: async (values) => {
            if (!user) {
                return;
            }
            const { uid } = user;
            const recipeFromFormValues = toRecipe(values, uid);
            await firebase.firestore.collection('recipes').add({
                ...recipeFromFormValues,
                owner: uid,
                created: new Date().toISOString(),
                updated: new Date().toISOString(),
                deleted: null,
            });
            const batch = firebase.firestore.batch();
            recipeFromFormValues.ingredients.forEach((ingr) => {
                const docRef = firebase.firestore
                    .collection(`users/${uid}/ingredients`)
                    .doc(ingr.name);
                batch.set(docRef, {
                    name: ingr.name,
                    unit: ingr.unit,
                    updated: new Date().toISOString(),
                });
            });
            await batch.commit();
            navigate('/recipes');
        },
    });
    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        setValues,
    } = formik;
    return (
        <RecipeForm
            isOwner={true}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            setValues={setValues}
            actionText="Create recipe"
            ingredientHistory={ingredientHistory}
        />
    );
};

const NewRecipe = () => {
    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);

    const { friends } = useSelector((state: RootState) => state.friends);

    const { data: ingredients, isLoading } = useGetIngredientsQuery();

    if (!ingredients || isLoading) {
        return <FullPageLoading />;
    }

    return (
        <Main title="New Recipe">
            {friends && user && ingredients && (
                <NewRecipeForm
                    friends={friends}
                    user={user}
                    ingredientHistory={ingredients}
                />
            )}
        </Main>
    );
};

export default NewRecipe;
