import React from 'react';
import { useParams } from 'react-router-dom';
import Main from '../../Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMilliseconds, format, formatDistanceToNow, parseISO, } from 'date-fns';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import DeleteListItem from '../../components/DeleteListItem';
import useDebounce from '../../debounce';
import FullPageLoading from '../../components/FullPageLoading';
import { useTranslation } from 'react-i18next';
import Owner from '../../components/Owner';
import Button from '../../components/Button';
import { api, useGetShoppingListByIdQuery, useUpdateShoppingListMutation } from '../../apis/firebaseApiSlice';
import { useDispatch } from 'react-redux';

const defaultEditable = () => {
    return {
        id: uuidv4(),
        text: '',
        isChecked: false,
        isDeleted: false,
        checklistType: 'adhoc' as 'adhoc',
    };
};

interface TickboxProps {
  handleTick: any
  checked: boolean
  text: string
  handleChange: (txt: string) => void
  handleDeleteItem: () => void
  deletable?: boolean
  disabled?: boolean
  addNewEditable: () => void
}

const Editable: React.FunctionComponent<TickboxProps> = ({
    handleTick,
    checked,
    text,
    deletable,
    handleChange,
    handleDeleteItem,
    disabled,
    addNewEditable,
}) => {
    return (
        <ShoppingListTickItem>
            <input type="checkbox" onChange={handleTick} checked={checked} />
            <ShoppingListInput
                type="text"
                disabled={disabled}
                value={text}
                onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        addNewEditable();
                    }
                }}
                autoFocus={text === ''}
                onChange={(e) => {
                    handleChange(e.target.value);
                }}
            />
            {deletable && <DeleteListItem handleDeleteItem={handleDeleteItem} />}
        </ShoppingListTickItem>
    );
};

const ShoppingListInput = styled.input`
  background-color: inherit;
  margin-left: 4px;
  margin-right: 4px;
  border: 0;
  font-weight: 300;
  color: rgb(72, 167, 112);
  width: 100%;
  overflow: scroll;
  font-size: 18px;

  &:active,
  &:focus {
    outline: none;
  }

  @media only screen and (min-width: 750px) {
    width: 400px;
  }
`;

const ShoppingListDetails = () => {
    const { id } = useParams();
    const [tickedOpen, setTickedOpen] = React.useState(true);
    const { t } = useTranslation();

    const { data: shoppingList } = useGetShoppingListByIdQuery(id);
    const [updateShoppingList] = useUpdateShoppingListMutation();

    const syncDelay = 1000;
    const shoppingListDebounce = useDebounce(shoppingList, syncDelay);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (
            shoppingListDebounce &&
      Object.keys(shoppingListDebounce).length > 0 &&
      differenceInMilliseconds(
          new Date(),
          parseISO(shoppingListDebounce.updated)
      ) <
        syncDelay * 3
        ) {
            const syncShoppingList = async (listToSync: any) => {
                updateShoppingList(listToSync);
            };
            syncShoppingList(shoppingListDebounce);
        }
    }, [id, updateShoppingList, shoppingListDebounce]);

    if (!shoppingList) {
        return <FullPageLoading />;
    }

    const handleTick = (tickedItemId: string) => {
        const newItems = shoppingList.items.map((item) => {
            if (tickedItemId === item.id) {
                return {
                    ...item,
                    isChecked: !item.isChecked,
                };
            }
            return item;
        });
        const newShoppingList = {
            ...shoppingList,
            items: newItems,
            updated: new Date().toISOString(),
        };

        dispatch(
            api.util.updateQueryData('getShoppingListById', id, () => {
                return newShoppingList;
            })
        );
    };

    const addEditable = () => {
        const newItems = [...shoppingList.items, defaultEditable()];
        const newShoppingList = {
            ...shoppingList,
            items: newItems,
            updated: new Date().toISOString(),
        };
        dispatch(
            api.util.updateQueryData('getShoppingListById', id, () => {
                return newShoppingList;
            })
        );
    };

    const changeText = (id: string, text: string) => {
        const newItems = shoppingList.items.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    text,
                };
            }
            return item;
        });
        const newShoppingList = {
            ...shoppingList,
            items: newItems,
            updated: new Date().toISOString(),
        };
        dispatch(
            api.util.updateQueryData('getShoppingListById', id, () => {
                return newShoppingList;
            })
        );
    };

    const deleteItem = (id: string) => {
        const newItems = shoppingList.items.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isDeleted: true,
                };
            }
            return item;
        });
        const newShoppingList = {
            ...shoppingList,
            items: newItems,
            updated: new Date().toISOString(),
        };
        dispatch(
            api.util.updateQueryData('getShoppingListById', id, () => {
                return newShoppingList;
            })
        );
    };

    const copy = async (unticked: any[]) => {
        const newClipText = unticked.map((item) => item.text).join('\n');
        await navigator.clipboard.writeText(newClipText);
    };

    if (!shoppingList.items) {
        return (
            <Main title={t('shoppingLists.details.title')}>
                <p>
          Unsupported version of shopping list, please create a new one instead
                </p>
            </Main>
        );
    }

    const checkboxContents = shoppingList.items
        .filter((item) => !item.isDeleted)
        .map((item) => {
            const { text, isChecked, id, checklistType } = item;
            return {
                key: id,
                isChecked,
                text,
                editable: checklistType === 'adhoc',
            };
        });

    const unticked = checkboxContents.filter(
        (checkboxContents) => !checkboxContents.isChecked
    );
    const ticked = checkboxContents.filter(
        (checkboxContents) => checkboxContents.isChecked
    );

    if (!shoppingList.created) {
        return (
            <div>
                <FontAwesomeIcon icon="spinner" spin />
            </div>
        );
    }

    return (
        <Main
            title={shoppingList.title || t('shoppingLists.details.title')}
            className="mw-500"
        >
            <ItemsWrapper>
                {unticked.map((checkboxContent) => {
                    const { text, key, isChecked, editable } = checkboxContent;
                    return (
                        <Editable
                            key={key}
                            handleTick={() => handleTick(key)}
                            checked={isChecked}
                            text={text}
                            handleDeleteItem={() => {
                                deleteItem(key);
                            }}
                            deletable={editable}
                            disabled={!editable}
                            handleChange={(txt) => {
                                changeText(key, txt);
                            }}
                            addNewEditable={addEditable}
                        />
                    );
                })}
                <TickedToggle role="button" onClick={addEditable}>
                    <div>
                        <FontAwesomeIcon icon="plus" />
                    </div>
                    <AddItem>{t('shoppingLists.details.addItem')}</AddItem>
                </TickedToggle>
                {ticked.length > 0 && (
                    <TickedContainer>
                        <TickedToggle
                            role="button"
                            onClick={() => setTickedOpen(!tickedOpen)}
                        >
                            <div>
                                <FontAwesomeIcon icon="angle-down" />
                            </div>
                            <div>
                                {t('shoppingLists.details.numOfTickedItems', {
                                    numItems: ticked.length,
                                })}
                            </div>
                        </TickedToggle>
                        <TickedList className={!tickedOpen ? 'hide' : ''}>
                            {ticked.map((checkboxContent) => {
                                const { text, key, isChecked } = checkboxContent;
                                return (
                                    <ShoppingListTickItem key={key}>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleTick(key)}
                                            checked={isChecked}
                                        />{' '}
                                        {text}
                                    </ShoppingListTickItem>
                                );
                            })}
                        </TickedList>
                    </TickedContainer>
                )}
            </ItemsWrapper>
            <h3 className="font-bold">
                {t('shoppingLists.details.recipeListTitle')}
            </h3>
            {shoppingList.recipes && (
                <ul>
                    {shoppingList.recipes.map((recipe) => (
                        <li key={recipe.id}>{recipe.title}</li>
                    ))}
                </ul>
            )}
            <Button buttonStyle={'secondary'} onClick={() => copy(unticked)}>
        Copy to clipboard
            </Button>
            {shoppingList.updated && (
                <DateContainer
                    title={`Created ${format(
                        parseISO(shoppingList.created),
                        'yyyy-MM-dd hh:mm'
                    )}`}
                >
          Edited {formatDistanceToNow(parseISO(shoppingList.updated))} ago
                </DateContainer>
            )}
            <OwnerContainer userId={shoppingList.owner} />
        </Main>
    );
};

const DateContainer = styled.div`
  text-align: end;
  font-size: 0.8em;
`;

const OwnerContainer = styled(Owner)`
  justify-content: flex-end;
  font-size: 0.8em;
`;

const AddItem = styled.div`
  cursor: text;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  font-size: 18px;

  @media only screen and (min-width: 750px) {
    max-width: 400px;
  }
`;

const ShoppingListTickItem = styled.div`
  display: flex;
  margin-bottom: 2px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:focus-within {
    border-top: 1px solid rgb(72, 167, 112, 0.4);
    border-bottom: 1px solid rgb(72, 167, 112, 0.4);
  }
`;

const TickedToggle = styled.div`
  display: flex;
  margin-left: 6px;
  width: 150px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  opacity: 0.6;

  & > div {
    margin-right: 8px;
  }

  &:hover {
    opacity: 1;
  }
`;

const TickedList = styled.div`
  transition: visibility 0s, opacity 0.5s linear;
  visibility: visible;
  opacity: 1;

  &.hide {
    visibility: hidden;
    opacity: 0;
  }
`;

const TickedContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 5px;
`;

export default ShoppingListDetails;
