import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Friend } from '../types';
import { useSelector } from 'react-redux';
import firebase from './Firebase/firebase';
import styled from '@emotion/styled';
import { RootState } from '../app/rootReducer';

interface OwnerProps {
  userId?: string
  className?: string
}

const Owner: React.FunctionComponent<OwnerProps> = ({ userId, className }) => {
    const { friends } = useSelector((state: RootState) => state.friends);

    const currentUser = firebase.auth.currentUser;
    const isCurrentUser = currentUser?.uid === userId;

    const friendOwner = (friends || []).find(
        (friend: Friend) => friend.uid === userId
    );

    const ownerDisplayName = isCurrentUser
        ? currentUser?.displayName || currentUser?.email
        : friendOwner?.email || userId;
    return (
        <OwnerWrapper title="Owner" className={className}>
            <div>
                <FontAwesomeIcon icon="user" />
            </div>
            <div>{ownerDisplayName}</div>
        </OwnerWrapper>
    );
};

const OwnerWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: 3px;
  }
`;
export default Owner;
