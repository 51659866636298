import React from 'react';
import styled from '@emotion/styled';

interface InputProps {
  label?: string
  name: string
  value: string | number
  type?: 'text' | 'password' | 'number'
  placeholder?: string
  width?: string
  className?: string
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  inputMode?:
    | 'none'
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url'
  autoFocus?: boolean
  autoComplete?: string
  disabled?: boolean
}

const Input: React.FunctionComponent<InputProps> = ({
    label,
    name,
    value,
    width,
    handleChange,
    inputMode,
    type,
    className,
    placeholder,
    autoFocus,
    autoComplete,
    disabled,
}) => {
    return (
        <InputWrapper className={className}>
            <div>{label && <label htmlFor={name}>{label}</label>}</div>
            <InputField
                id={name}
                name={name}
                type={type || 'text'}
                placeholder={placeholder}
                width={width}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                value={value}
                inputMode={inputMode}
                onChange={handleChange}
                disabled={disabled}
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding-right: 20px;
`;

const InputField = styled.input`
  padding: 12px 2px;
  width: 100%;
  border: 1px solid rgb(238, 238, 238, 0.3);

  @media only screen and (min-width: 750px) {
    width: ${(props) => (props.width ? props.width : '100%')};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
    border: 1px solid rgb(238, 238, 238, 0.3);
  }
`;

export default Input;
