import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { myFirebase } from '../../components/Firebase/firebase';
import { Link, useNavigate } from 'react-router-dom';
import Main from '../../Main';
import Button from '../../components/Button';
import styled from '@emotion/styled';
import Overview from '../../components/Overview';
import LinkButton from '../../components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PersistedRecipe } from '../../types';
import { recipesToShoppingList } from '../../shoppingLists/recipesToShoppingList';
import { useTranslation } from 'react-i18next';
import Owner from '../../components/Owner';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { getAuth } from 'firebase/auth';
import { useCreateShoppingListMutation, useGetIngredientsQuery, useGetRecipesQuery } from '../../apis/firebaseApiSlice';
import FullPageLoading from '../../components/FullPageLoading';

const Recipes = () => {
    const [shoppingList, setShoppingList] = React.useState<any[]>([]);
    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { friends } = useSelector((state: RootState) => state.friends);
    const [storeShoppingList, storeShoppingListResult] = useCreateShoppingListMutation();
    const addToShoppingList = (recipe: PersistedRecipe) => {
        const newShoppingList = shoppingList.filter(
            (shoppingListRecipe) => shoppingListRecipe.id !== recipe.id
        );
        if (newShoppingList.length === shoppingList.length) {
            setShoppingList([...shoppingList, recipe]);
        } else {
            setShoppingList(newShoppingList);
        }
    };

    if (storeShoppingListResult.isSuccess) {
        navigate(`/shoppinglists/${storeShoppingListResult.data.id}`);
    }

    const createShoppingList = async () => {
        if (!user || !friends || !ingredients) {
            console.log('User is not defined');
            return;
        }
        const { uid } = user;
        const converted = recipesToShoppingList(shoppingList, ingredients, t);
        const list = {
            ...converted,
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            owner: uid,
            collaborators: [
                uid,
                ...friends
                    .filter((friend) => friend.alwaysShareWith)
                    .map((friend) => friend.uid),
            ],
        };
        storeShoppingList(list);
    };

    const { data: recipes, isLoading: isLoadingRecipes } = useGetRecipesQuery();
    const { data: ingredients, isLoading: isLoadingIngredients } = useGetIngredientsQuery();


    if (!recipes || isLoadingRecipes || !ingredients || isLoadingIngredients) {
        return <FullPageLoading />;
    }

    const selectedRecipeIds = shoppingList.map((recipe) => recipe.id);

    const shoppingListLengthText =
    shoppingList.length > 0 ? `(${shoppingList.length})` : '';
    return (
        <Main title={t('recipes.list.title')}>
            <ButtonGroup>
                <LinkButton to="/recipes/new">
                    {t('recipes.list.newRecipeButton')}
                </LinkButton>
                <Button
                    type="button"
                    disabled={shoppingList.length === 0}
                    buttonStyle="secondary"
                    onClick={createShoppingList}
                >
                    {t('recipes.list.newShoppingListButton')}
                    {shoppingListLengthText}
                </Button>
            </ButtonGroup>
            <Overview>
                {recipes.map((recipe) => {
                    return (
                        <RecipeCard key={recipe.id}>
                            <RecipeCardHeader>
                                <SelectRecipe
                                    onClick={() => addToShoppingList(recipe)}
                                    selected={selectedRecipeIds.includes(recipe.id)}
                                >
                                    {!selectedRecipeIds.includes(recipe.id) && (
                                        <FontAwesomeIcon icon={['far', 'check-circle']} />
                                    )}
                                    {selectedRecipeIds.includes(recipe.id) && (
                                        <FontAwesomeIcon icon="check-circle" />
                                    )}
                                </SelectRecipe>
                                <h3>{recipe.title}</h3>
                            </RecipeCardHeader>
                            <RecipeCardBody>
                                <Owner userId={recipe.owner} />
                            </RecipeCardBody>
                            <RecipeCardFooter>
                                <div>
                                    <Link to={`/recipes/${recipe.id}`}>
                                        {t('recipes.list.viewRecipe')}
                                    </Link>
                                </div>
                            </RecipeCardFooter>
                        </RecipeCard>
                    );
                })}
            </Overview>
        </Main>
    );
};

interface SelectRecipeProps {
  selected?: boolean
}

const SelectRecipe = styled.div`
  color: white;
  opacity: ${(props: SelectRecipeProps) => (props.selected ? '0.8' : '0.2')};
  cursor: pointer;
  margin-right: 5px;
  font-size: 20px;

  &:hover {
    opacity: 0.6;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const RecipeCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 2px 5px;
  font-size: 16px;
`;

const RecipeCardHeader = styled.header`
  display: flex;

  & > h3 {
    margin-top: 0;
    font-size: 20px;
  }
`;

const RecipeCardBody = styled.main``;

const RecipeCardFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  & > a,
  a:visited,
  a:link {
    color: #fff;
  }
`;

export default Recipes;
