import { configureStore } from '@reduxjs/toolkit';
import { api } from '../apis/firebaseApiSlice';
import { rootReducer } from './rootReducer';

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});
export default store;
