import convertUnits from 'convert-units';
import { Unit } from '../types';

class Convert {
    private readonly val: number;
    // @ts-ignore
    private fromUnit: Unit;

    constructor(numerator: number, denominator?: number) {
        if (denominator) {
            this.val = numerator / denominator;
        } else {
            this.val = numerator;
        }
    }
    from(from: Unit): this {
        this.fromUnit = from;
        return this;
    }

    to(to: Unit): number {
        if (['pcs', 'cloves'].includes(this.fromUnit)) {
            if (this.fromUnit === 'pcs' && to === 'cloves') {
                return parseFloat((this.val * 12).toFixed(2));
            } else if (this.fromUnit === 'cloves' && to === 'pcs') {
                return parseFloat((this.val / 12).toFixed(2));
            } else if (
                (this.fromUnit === 'pcs' && to === 'pcs') ||
        (this.fromUnit === 'cloves' && to === 'cloves')
            ) {
                return this.val;
            } else {
                throw Error('Conversion not supported');
            }
        }
        return convertUnits(this.val)
            .from(this.fromUnit as unit)
            .to(to as unit);
    }

    toBest(options?: {
    exclude?: Unit[]
    cutOffNumber?: number
  }): { val: number, unit: string, singular: string, plural: string } {
        if ('cloves' === this.fromUnit) {
            if (this.val >= 12) {
                return {
                    val: this.to('pcs'),
                    unit: 'pcs',
                    singular: 'piece',
                    plural: 'pieces',
                };
            }
            return {
                val: this.val,
                unit: 'cloves',
                singular: 'clove',
                plural: 'cloves',
            };
        } else if ('pcs' === this.fromUnit) {
            return {
                val: this.val,
                unit: 'pcs',
                singular: 'piece',
                plural: 'pieces',
            };
        }

        const opts = {
            exclude: options?.exclude
                ? ((options.exclude as unknown) as unit[])
                : undefined,
            cutOffNumber: options?.cutOffNumber,
        };
        return convertUnits(this.val)
            .from(this.fromUnit as unit)
            .toBest(opts);
    }
    // getUnit<T extends unit>(abbr: T): { abbr: T, measure: measure, system: system, unit: { name: { singular: string, plural: string }, to_anchor: number } };
    // describe<T extends unit>(abbr: T): { abbr: T, measure: measure, system: system, singular: string, plural: string };
    // list(measure?: measure): unit[];
    // private throwUnsupportedUnitError(what: string): void;
    // possibilities(measure?: measure): unit[];
    // measures(): measure[];
}

type uMass = 'mcg' | 'mg' | 'g' | 'kg' | 'oz' | 'lb' | 'mt' | 't'; // Mass
type uVolume =
  | 'mm3'
  | 'cm3'
  | 'ml'
  | 'l'
  | 'kl'
  | 'm3'
  | 'km3'
  | 'tsp'
  | 'Tbs'
  | 'in3'
  | 'fl-oz'
  | 'cup'
  | 'pnt'
  | 'qt'
  | 'gal'
  | 'ft3'
  | 'yd3'; // Volume

type unit = uMass | uVolume;

const convert = (value: number) => {
    return new Convert(value);
};
export default convert;
