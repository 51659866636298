import React from 'react';
import Input from './Input';
import Button from './Button';
import styled from '@emotion/styled';
import { IngredientFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import Toggle from './Toggle';
import UnitChooser from './UnitChooser';

interface IngredientFormProps {
  values: IngredientFormValues
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  actionText: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any
}

const IngredientForm: React.FunctionComponent<IngredientFormProps> = ({
    values,
    handleChange,
    handleSubmit,
    actionText,
    setFieldValue,
}) => {
    const { t } = useTranslation();
    return (
        <FormWrapper onSubmit={handleSubmit}>
            <FormPartWrapper>
                <FormPart>
                    <Input
                        label={t('ingredients.ingredientForm.values.name')}
                        name="name"
                        value={values.name}
                        handleChange={handleChange}
                        disabled
                    />
                    <Input
                        label={t('ingredients.ingredientForm.values.category')}
                        name="category"
                        value={values.category ?? ''}
                        handleChange={handleChange}
                    />
                    <UnitChooser
                        label={t('ingredients.ingredientForm.values.unit')}
                        name="unit"
                        value={values.unit}
                        onChange={(name, selectedValue) => {
                            setFieldValue('unit', selectedValue);
                        }}
                    />
                    <Toggle
                        name="isBasicIngredient"
                        value={values.isBasicIngredient || false}
                        handleChange={() => {
                            setFieldValue('isBasicIngredient', !values.isBasicIngredient);
                        }}
                        label={t('ingredients.ingredientForm.values.isBasicIngredient')}
                    />
                </FormPart>
            </FormPartWrapper>
            <div>
                <Button type="submit">{actionText}</Button>
            </div>
        </FormWrapper>
    );
};

const FormWrapper = styled.form`
  font-size: 16px;

  @media only screen and (min-width: 1300px) {
    max-width: 1100px;
  }
`;

const FormPartWrapper = styled.div`
  @media only screen and (min-width: 1300px) {
    display: flex;
    justify-content: space-between;
  }
`;

const FormPart = styled.div`
  margin-bottom: 20px;
  border: none;
  min-width: 0;
  padding: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 500px;
  }
`;

export default IngredientForm;
