import React, { useState } from 'react';
import { useFormik } from 'formik';
import Main from '../../Main';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import firebase from '../../components/Firebase/firebase';
import styled from '@emotion/styled';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import LinkButton from '../../components/LinkButton';

interface FormValues {
  email: string
  passwordOne: string
  passwordTwo: string
}

const ValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required(),
    passwordOne: Yup.string().min(8, 'Too short'),
    passwordTwo: Yup.string().min(8, 'Too short'),
});

function SignUpForm() {
    const [error, setError] = useState(undefined);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: '',
            passwordOne: '',
            passwordTwo: '',
        },
        onSubmit: (values: FormValues) => {
            firebase.auth
                .createUserWithEmailAndPassword(values.email, values.passwordOne)
                .then((authUser) => {
                    navigate('/home');
                })
                .catch((error) => {
                    setError(error);
                });
        },
        validationSchema: ValidationSchema,
    });
    return (
        <div>
            <Header>
                <LinkButton to="/signin">{t('signup.signinButton')}</LinkButton>
            </Header>
            <Main className="w-500 center" title={t('signup.title')}>
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <Input
                        label={t('signup.email')}
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                    />
                    <Input
                        label={t('signup.passwordOne')}
                        name="passwordOne"
                        autoComplete="new-password"
                        value={formik.values.passwordOne}
                        handleChange={formik.handleChange}
                        type="password"
                    />
                    <Input
                        label={t('signup.passwordTwo')}
                        name="passwordTwo"
                        autoComplete="new-password"
                        value={formik.values.passwordTwo}
                        handleChange={formik.handleChange}
                        type="password"
                    />
                    <Button
                        type="submit"
                        disabled={formik.errors && Object.keys(formik.errors).length > 0}
                    >
                        {t('signup.signupButton')}
                    </Button>
                    {error && <p>{error}</p>}
                </FormWrapper>
            </Main>
        </div>
    );
}

const Header = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
`;

const FormWrapper = styled.form`
  @media only screen and (min-width: 750px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

export default SignUpForm;
