import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { myFirebase } from './Firebase/firebase';
import { getAuth } from 'firebase/auth';
import FullPageLoading from './FullPageLoading';

interface RequireAuthProps {
    redirect?: string
    reverse?: boolean
    children: JSX.Element
}

export const RequireAuth = ({ children, redirect, reverse }: RequireAuthProps) => {
    const auth = getAuth(myFirebase);
    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    if (loading) {
        return <FullPageLoading/>;
    }

    const authed = reverse ? !user : !!user;
    if (!authed) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={redirect ? redirect : '/signin'} state={{ from: location }} replace/>;
    }

    return children;
};
