import React, { useState } from 'react';
import { useFormik } from 'formik';
import Main from '../../Main';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import Button from '../../components/Button';
import firebase from '../../components/Firebase/firebase';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import LinkButton from '../../components/LinkButton';

interface FormValues {
  email: string
  password: string
}

const ValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required(),
    password: Yup.string().required('Password is required'),
});

function SignInForm() {
    const [error, setError] = useState<any>(undefined);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const formik = useFormik({
        validateOnMount: false,
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: (values: FormValues) => {
            firebase.auth
                .signInWithEmailAndPassword(values.email, values.password)
                .then((authUser: any) => {
                    navigate('/recipes');
                })
                .catch((error: any) => {
                    setError(error);
                });
        },
        validationSchema: ValidationSchema,
    });

    return (
        <div>
            <Header>
                <LinkButton to="/signup">{t('signin.signupButton')}</LinkButton>
            </Header>
            <Main title={t('signin.title')} className="center w-500">
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <Input
                        label={t('signin.email')}
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                    />
                    <Input
                        label={t('signin.password')}
                        name="password"
                        value={formik.values.password}
                        handleChange={formik.handleChange}
                        autoComplete="current-password"
                        type="password"
                    />
                    <Button
                        type="submit"
                        disabled={formik.errors && Object.keys(formik.errors).length > 0}
                    >
                        {t('signin.signInButton')}
                    </Button>
                    {error && <p>{error.message}</p>}
                </FormWrapper>
            </Main>
        </div>
    );
}

const Header = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
`;

const FormWrapper = styled.form`
  @media only screen and (min-width: 750px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

export default SignInForm;
