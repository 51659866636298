import React, { useState } from 'react';
import firebase, { myFirebase } from '../../components/Firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Main from '../../Main';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import Toggle from '../../components/Toggle';
import { useTranslation } from 'react-i18next';
import { getAuth, UserInfo } from 'firebase/auth';

interface PublicProfile {
  email: string
  displayName: string
  isDiscoverable: boolean
  fromDatabase: boolean
}

interface PublicProfileFormProps {
  user: UserInfo
  publicProfile: PublicProfile
}

const PublicProfileForm: React.FunctionComponent<PublicProfileFormProps> = ({
    user,
    publicProfile,
}) => {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            email: publicProfile.email,
            displayName: publicProfile.displayName,
            isDiscoverable: publicProfile.isDiscoverable,
            fromDatabase: publicProfile.fromDatabase,
        },
        onSubmit: async (values) => {
            const { email, isDiscoverable, displayName, fromDatabase } = values;
            const publicProfile = {
                email,
                displayName,
                isDiscoverable,
                updated: new Date().toISOString(),
            };
            if (fromDatabase) {
                await firebase.firestore
                    .collection(`publicUsers`)
                    .doc(user.uid)
                    .update({
                        ...publicProfile,
                    });
            } else {
                await firebase.firestore
                    .collection('publicUsers')
                    .doc(user.uid)
                    .set({
                        ...publicProfile,
                        created: new Date().toISOString(),
                    });
            }
        },
    });

    return (
        <FormWrapper onSubmit={formik.handleSubmit}>
            <div>
                <Toggle
                    name="isDiscoverable"
                    value={formik.values.isDiscoverable}
                    handleChange={formik.handleChange}
                    label={t('profile.edit.discoverableToggleLabel')}
                />
            </div>
            <Button type="submit">{t('profile.edit.save')}</Button>
        </FormWrapper>
    );
};

const EditProfile = () => {
    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);
    const [publicProfile, setPublicProfile] = useState<PublicProfile | undefined>(
        undefined
    );
    const { t } = useTranslation();

    React.useEffect(() => {
        if (!user) {
            return;
        }
        firebase.firestore
            .collection(`publicUsers`)
            .doc(user.uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const data = { ...doc.data(), fromDatabase: true };
                    setPublicProfile(data as PublicProfile);
                } else {
                    console.log('No such document!');
                    setPublicProfile({
                        email: user.email || '',
                        displayName: user.displayName || '',
                        isDiscoverable: false,
                        fromDatabase: false,
                    });
                }
            })
            .catch((error) => {
                console.log('Error getting document:', error);
                setPublicProfile({
                    email: user.email || '',
                    displayName: user.displayName || '',
                    isDiscoverable: false,
                    fromDatabase: false,
                });
            });
    }, [user]);

    return (
        <Main title={t('profile.edit.title')}>
            {publicProfile && user && (
                <PublicProfileForm publicProfile={publicProfile} user={user} />
            )}
        </Main>
    );
};

const FormWrapper = styled.form`
  @media only screen and (min-width: 750px) {
    max-width: 400px;
  }
`;

export default EditProfile;
