import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from '@emotion/styled';

interface DeleteListItemProps {
  handleDeleteItem: () => void
  className?: string
}

const DeleteListItem: React.FunctionComponent<DeleteListItemProps> = ({
    handleDeleteItem,
    className,
}) => {
    return (
        <StyledDeleteListItem
            className={className}
            onClick={handleDeleteItem}
            role="button"
        >
            <FontAwesomeIcon icon="times" />
        </StyledDeleteListItem>
    );
};

const StyledDeleteListItem = styled.div`
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export default DeleteListItem;
