import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase, { myFirebase } from './components/Firebase/firebase';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import HideUnauth from './components/HideUnauth';
import { useTranslation } from 'react-i18next';
import { mergeRefs, useLayer } from 'react-laag';
import LanguageChooser from './components/popupmenu/LanguageChooser';
import { getAuth } from 'firebase/auth';
import { PopupMenu, PopupMenuItem } from './components/popupmenu/PopupMenu';

const Layout: React.FunctionComponent<any> = ({ children }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const ref = React.useRef(null);
    const ref2 = React.useRef(null);
    const { t } = useTranslation();

    const showSidebar = () => setSidebarVisible(true);
    const hideSidebar = () => setSidebarVisible(false);

    useOnClickOutside(ref, hideSidebar);


    const [profilePopupOpen, setProfilePopupOpen] = React.useState(false);
    const close = () => {
        setProfilePopupOpen(false);
    };

    const { layerProps, renderLayer, arrowProps, triggerProps } = useLayer({
        isOpen: profilePopupOpen,
        onOutsideClick: close,
        onDisappear: close,
        placement: 'bottom-end',
    });

    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);
    return (
        <>
            <AppContainer authed={!!user}>
                <HideUnauth>
                    <Header>
                        <HideDesktop>
                            <MenuToggleAndLogo>
                                <MenuToggle
                                    onClick={showSidebar}
                                    role="button"
                                    aria-expanded={sidebarVisible}
                                >
                                    <FontAwesomeIcon icon="bars" />
                                </MenuToggle>
                                <TopLogo>FeedUs</TopLogo>
                            </MenuToggleAndLogo>
                        </HideDesktop>
                        <User>
                            <IconButton
                                type="button"
                                ref={mergeRefs(triggerProps.ref, ref2)}
                                onClick={() => setProfilePopupOpen(true)}>
                                <FontAwesomeIcon icon={'user'} />
                            </IconButton>
                            {renderLayer(
                                <div>
                                    {profilePopupOpen && <PopupMenu {...layerProps} arrowProps={arrowProps}>
                                        <LanguageChooser/>
                                        <PopupMenuItem onClick={() => firebase.auth.signOut()}>
                                            {t('userMenu.signOut')}
                                        </PopupMenuItem>
                                    </PopupMenu>}
                                </div>
                            )}
                        </User>
                    </Header>
                    <StyledSidebar
                        ref={ref}
                        authed={!!user}
                        menuExpanded={sidebarVisible}
                    >
                        <CloseIcon
                            onClick={() => {
                                setSidebarVisible(false);
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </CloseIcon>
                        <Logo>FeedUs</Logo>
                        <ul>
                            <li>
                                <Link to="/" onClick={hideSidebar}>
                                    <Icon icon="tachometer-alt-fast" />
                                    <span>{t('nav.dashboard')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/recipes" onClick={hideSidebar}>
                                    <Icon icon="fish" />
                                    <span>{t('nav.recipes')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/shoppinglists" onClick={hideSidebar}>
                                    <Icon icon="clipboard-check" />
                                    <span>{t('nav.shoppingLists')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile/friends" onClick={hideSidebar}>
                                    <Icon icon="user-friends" />
                                    <span>{t('nav.friends')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ingredients" onClick={hideSidebar}>
                                    <Icon icon="carrot" />
                                    <span>{t('nav.ingredients')}</span>
                                </Link>
                            </li>
                        </ul>
                    </StyledSidebar>
                </HideUnauth>
                <Content>{children}</Content>
                <Footer>
                    <div>
                        <Copyright>&copy; 2020 abbsnabb</Copyright>
                        <Attributions>
              Flag icons made by{' '}
                            <a
                                href="https://www.flaticon.com/authors/freepik"
                                title="Freepik"
                            >
                Freepik
                            </a>{' '}
              from{' '}
                            <a href="https://www.flaticon.com/" title="Flaticon">
                                {' '}
                www.flaticon.com
                            </a>
                        </Attributions>
                    </div>
                    <div>Made for the love of food</div>
                </Footer>
            </AppContainer>
        </>
    );
};

const IconButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  
  &:hover {
    color: rgb(56, 138, 90);
  }
`;

const MenuToggleAndLogo = styled.div`
  display: flex;
  font-size: 1.2em;
`;

const Copyright = styled.div`
  margin-bottom: 15px;
`;

const Attributions = styled.div``;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  min-width: 25px;
`;

const CloseIcon = styled.div`
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #ddd;

  @media only screen and (min-width: 750px) {
    visibility: hidden;
  }
`;

const HideDesktop = styled.div`
  @media only screen and (min-width: 750px) {
    visibility: hidden;
  }
`;

interface AppContainerProps {
  authed: boolean
}

const AppContainer = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr); /* Side nav is hidden on mobile */
  grid-template-rows: 50px 1fr 100px;
  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100vh;

  @media only screen and (min-width: 750px) {
    grid-template-columns: ${(props: AppContainerProps) =>
        props.authed
            ? '240px 1fr'
            : '0 1fr'}; /* Show the side nav for non-mobile screens */
    grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
  }
`;

const MenuToggle = styled.div`
  cursor: pointer;

  @media only screen and (min-width: 750px) {
    display: none;
  }
`;

interface StyledSidebarProps {
  menuExpanded: boolean
  authed: boolean
}

const StyledSidebar = styled.aside`
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position: fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2; /* Needs to sit above the hamburger menu icon */
  background-color: rgb(72, 167, 112);
  transform: ${(props: StyledSidebarProps) =>
        props.menuExpanded ? 'translateX(0)' : 'translateX(-245px)'};
  transition: all 0.3s ease-in-out;

  @media only screen and (min-width: 750px) {
    position: relative; /* Fixed position on mobile */
    transform: translateX(0);
  }

  & > ul {
    list-style: none;
    //margin-top: 50px;
    padding: 0;
    font-size: 16px;

    li:first-of-type a {
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2),
        inset 0 1px rgba(0, 0, 0, 0.2);
    }

    li {
      a {
        display: block;
        padding: 1em 1em 1em 1.2em;
        outline: none;
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
        color: #f3efe0;
        text-transform: uppercase;
        text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
        letter-spacing: 1px;
        font-weight: 400;
        -webkit-transition: background 0.3s, box-shadow 0.3s;
        transition: background 0.3s, box-shadow 0.3s;
        text-decoration: none;
      }

      a:hover {
        background: rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
        color: #fff;
      }
    }
  }
`;

const Logo = styled.div`
  color: #fff;
  padding: 20px;
  font-size: 1.8em;
`;

const TopLogo = styled.div`
  margin-left: 10px;
`;

const Content = styled.main`
  grid-area: main;
`;

const Header = styled.header`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const Footer = styled.header`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 16px;
`;

const User = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export default Layout;
