import React, { useState } from 'react';
import firebase, { myFirebase } from '../../components/Firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Main from '../../Main';
import Input from '../../components/Input';
import Button from '../../components/Button';
import styled from '@emotion/styled';
import { PublicUser } from '../../types';
import { addFriend, updateFriend } from './friendSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import Toggle from '../../components/Toggle';
import { useTranslation } from 'react-i18next';
import { getAuth } from 'firebase/auth';

const EditFriends = () => {
    const auth = getAuth(myFirebase);
    const [user] = useAuthState(auth);
    const [newFriend, setNewFriend] = useState('');
    const [users, setUsers] = useState<PublicUser[]>([]);
    const { t } = useTranslation();
    const { friends } = useSelector((state: RootState) => state.friends);

    const dispatch = useDispatch();

    const findUserByEmail = (email: string) => {
        if (!user) {
            return;
        }
        firebase.firestore
            .collection('publicUsers')
            .where('email', '==', email)
            .where('isDiscoverable', '==', true)
            .get()
            .then((querySnapshot) => {
                const response: any[] = [];
                querySnapshot.forEach((doc) => {
                    const documentData = doc.data();
                    response.push({ ...documentData, uid: doc.id, id: doc.id });
                });
                setUsers(response);
            })
            .catch((error) => {
                console.log('Error getting documents: ', error);
            });
    };
    const existingFriends = (friends || []).map((friend) => friend.uid);

    return (
        <Main title={t('profile.friends.title')}>
            <Wrapper>
                <h2>{t('profile.friends.addFriendTitle')}</h2>
                <Input
                    label={t('profile.friends.email')}
                    name={'email'}
                    value={newFriend}
                    handleChange={(e) => {
                        // @ts-ignore
                        const value = e.target ? e.target.value : '';
                        setNewFriend(value as string);
                    }}
                />
                <Button onClick={() => findUserByEmail(newFriend)}>
                    {t('profile.friends.findButton')}
                </Button>

                {users && (
                    <ul>
                        {users
                            .filter(
                                (friendUser: PublicUser) =>
                                    !existingFriends.includes(friendUser.uid)
                            )
                            .map((friendUser: PublicUser) => {
                                return (
                                    <li
                                        key={friendUser.uid}
                                        onClick={() => dispatch(addFriend(friendUser))}
                                    >
                                        {friendUser.email}
                                    </li>
                                );
                            })}
                    </ul>
                )}

                <h2>{t('profile.friends.friendsListTitle')}</h2>

                {friends && (
                    <FriendList>
                        {friends.map((friend) => {
                            return (
                                <FriendListItem key={friend.uid}>
                                    <div>{friend.email}</div>
                                    <div>{friend.displayName}</div>
                                    <div>
                                        <Toggle
                                            name={`alwaysShareWith-${friend.uid}`}
                                            value={friend.alwaysShareWith}
                                            handleChange={() => {
                                                const updatedFriend = {
                                                    ...friend,
                                                    alwaysShareWith: !friend.alwaysShareWith,
                                                };
                                                dispatch(updateFriend(updatedFriend));
                                            }}
                                            label={t('profile.friends.alwaysShareWith')}
                                        />
                                    </div>
                                </FriendListItem>
                            );
                        })}
                    </FriendList>
                )}
            </Wrapper>
        </Main>
    );
};

const Wrapper = styled.div`
  @media only screen and (min-width: 750px) {
    max-width: 400px;
  }
`;

const FriendList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FriendListItem = styled.li`
  padding: 5px;
  //border: 1px solid red;
  margin-bottom: 5px;
`;

export default EditFriends;
