import React from 'react';
import Main from '../../Main';
import styled from '@emotion/styled';
import Overview from '../../components/Overview';
import { IngredientHistoryItem } from '../../types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetIngredientsQuery } from '../../apis/firebaseApiSlice';
import FullPageLoading from '../../components/FullPageLoading';

const Ingredients = () => {
    const { t } = useTranslation();

    const { data: ingredients, isLoading } = useGetIngredientsQuery();

    if (!ingredients || isLoading) {
        return <FullPageLoading />;
    }
    const categories = [
    // @ts-ignore
        ...new Set(ingredients.map((i: IngredientHistoryItem) => i.category ?? '')),
    ]
        .filter((key) => !!key)
        .sort();

    return (
        <Main title={t('ingredients.list.title')}>
            <Overview>
                {ingredients.map((ingredient) => {
                    return (
                        <IngredientCard
                            key={ingredient.name}
                            category={ingredient.category}
                        >
                            <RecipeCardHeader>
                                <h3>{ingredient.name}</h3>
                            </RecipeCardHeader>
                            <IngredientCardBody>
                                <IngredientList>
                                    <IngredientListItem>
                                        <div>{t('ingredients.list.item.id')}</div>
                                        <div>{ingredient.id}</div>
                                    </IngredientListItem>
                                    <IngredientListItem>
                                        <div>{t('ingredients.list.item.name')}</div>
                                        <div>{ingredient.name}</div>
                                    </IngredientListItem>
                                    <IngredientListItem>
                                        <div>{t('ingredients.list.item.unit')}</div>
                                        <div>{ingredient.unit}</div>
                                    </IngredientListItem>
                                    <IngredientListItem>
                                        <div>{t('ingredients.list.item.category')}</div>
                                        <div>{ingredient.category}</div>
                                    </IngredientListItem>
                                    <IngredientListItem>
                                        <div>{t('ingredients.list.item.isBasicIngredient')}</div>
                                        <div>{ingredient.isBasicIngredient ? 'yes' : 'no'}</div>
                                    </IngredientListItem>
                                </IngredientList>
                            </IngredientCardBody>
                            <IngredientCardFooter>
                                <div>
                                    <Link to={`/ingredients/${ingredient.id}/edit`}>
                                        {t('ingredients.list.editIngredient')}
                                    </Link>
                                </div>
                            </IngredientCardFooter>
                        </IngredientCard>
                    );
                })}
            </Overview>
            {categories && categories.length > 0 && (
                <React.Fragment>
                    <h2>Categories</h2>
                    <ul>
                        {categories.map((cat) => (
                            <li key={cat}>{cat}</li>
                        ))}
                    </ul>
                </React.Fragment>
            )}
        </Main>
    );
};

const IngredientCard = styled.div<{ category: string | undefined }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 2px 5px;
  font-size: 16px;
  filter: ${(props) => (!props.category ? 'brightness(50%)' : 'inherit')};
`;

const RecipeCardHeader = styled.header`
  display: flex;

  & > h3 {
    margin-top: 0;
    font-size: 20px;
  }
`;

const IngredientCardBody = styled.main``;

const IngredientCardFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  & > a,
  a:visited,
  a:link {
    color: #fff;
  }
`;

const IngredientListItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

const IngredientList = styled.ul`
  list-style: none;
  padding-left: 0;
`;
export default Ingredients;
